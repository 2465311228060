@import "../../libs/flexproperties";
@import "../../libs/mediaquerie";

///////////////////////////
/// Main.tsx
.maincontainer {
  @include colum_flex();
}

////////////////////////////
/// PageOne.tsx

.pageonecontainer {
  width: 100%;
  height: 100%;
}

.pageonewrapper {
}
.pageone {
}
.pageone {
}
////////////////////////////
/// PageTwo.ts
.pagetwocontainer {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-x: hidden;
  @include response-media(small) {
    flex-flow: column;
  }
}

.pagetwophoto {
  width: 35%;
  overflow: hidden;
  position: relative;
  @include response-media(large) {
    width: 45%;
  }
  @include response-media(small) {
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .pagetwolayer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.01;
  }

  // wraps button in line 22
  .pagetwobutton{
    width: 100%;
    height: 3em;
    position: absolute;
    top: 70%;
    display: flex;
    justify-content: center;
    // z-index: 700;
    
    
  }
}

.pagetwoinfo {
  width: 65%;
  border: 2px solid red;
  @include response-media(large) {
    width: 55%;
  }
  @include response-media(small) {
    width: 100%;
  }

}

////////////////////////////
/// Navigation.ts
.navigationcontainer {
  width: 100%;
  height: 4em;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;

  justify-content: center;
}
.navigationsubcontainer {
  width: 90%;
  height: 4em;
  border-bottom: 2px solid white;
  display: flex;
  align-items: center;
  z-index: 700;

  img {
    height: 2.5em;
  }
}
.navigationopacity {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, black 20%, rgb(125, 125, 125));
  opacity: 0.29;
}
