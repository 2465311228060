body {
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', sans-serif;
  &::-webkit-scrollbar {
    width: 0.5em;
   
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: rgb(80, 80, 80);
    
  }
  &::-webkit-scrollbar-track{
    background: rgb(173, 171, 171);
  }
}
