.videocontainer {
  
  video {
    // eliminate the extra margin at the bottom
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    border: none;
    
  }
  
}
