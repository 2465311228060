@mixin button_white() {
  width: auto;
  // height: 2em;
  padding: 1em;
  border: none;
  letter-spacing: 0.1em;
  background: white;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0.2em;
  transition: opacity 0.6s ease-in-out;
  &:hover {
    opacity: 0.85;
  }
}
