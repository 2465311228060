//////////////////////////////////////////////
/// 1em = 16px Ex: 576 / 16
// 1em mesurement works better for browsers
/// MEDIA QUIERIE MANAGER
$xs: 0;
$sm: 36.5em; // 576px
$md: 51.25em; // 820px
$lg: 62em; // 992px
$xl: 81.25em; //1300px
$xxl: 93.75em; // 1500px
$xxxl: 125em; //2000px

// @mixin reusability for media use
// @content allow to input an entire code block
// @breakpoint is the parameter being passed
// from larger to small order for media to work properly
@mixin response-media($breakpoint) {
  @if ($breakpoint == xxxlarge) {
    @media (max-width: $xxxl) {
      @content;
    } // 2000px
  } @else if ($breakpoint == xxlarge) {
    @media (max-width: $xxl) {
      @content;
    } // 1500px
  } @else if ($breakpoint == xlarge) {
    @media (max-width: $xl) {
      @content;
    } // 1300px
  } @else if($breakpoint == large) {
    @media (max-width: $lg) {
      @content;
    } // 992px
  } @else if($breakpoint == medium) {
    @media (max-width: $md) {
      @content;
    } // 820px
  } @else if($breakpoint == small) {
    @media (max-width: $sm) {
      @content; //576px
    }
  }
}